.loading {
    background-color: rgb(0 0 0);
    position: fixed;
    inset: 0 0 0 0;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    z-index: -10;
    visibility: hidden;
    transition: all 0.5s;
}

.loading.show {
    opacity: 1;
    pointer-events: auto;
    z-index: 10000000;
    visibility: visible;
}

.shop.main-content {
    padding: 82px 0 0px;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* .Section_onepad_own {
    padding-top: 130px;
    padding-bottom: 170px;
} */

.p-own {
    padding: 90px 0;
}

.number_pro_se,
.number_pro_se_lft {
    display: flex;
    align-items: center;
}


.heading-container-second {
    text-align: center;
    max-width: 600px;
    position: relative;
    margin-left: auto;
}

.our-story-head.heading-container-second p::after {
    right: -40%;
    width: 35%;
}

.number_pro_se h4,
.number_pro_se_do h4 {
    margin: 0;
    padding-left: 5%;
}

.number_pro_se_lft h4 {
    margin: 0;
    padding-right: 5%;
}

.number_pro_se span,
.number_pro_se_lft span,
.number_pro_se_do span {
    background: #b7ac50;
    height: 45px;
    border-radius: 100%;
    border: 3px solid #212529;
    width: 45px;
    padding-left: 3px;
    line-height: 55px;
    color: #fff;
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.middle_pro_img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.number_pro_se_do,
.left_product_section {
    display: flex;
    justify-content: start;
}

.number_pro_se_lft {
    justify-content: end;
    display: flex;
}

.number_pro_se_do {
    align-items: center;
}

.left_product_section {
    flex-direction: column;
    justify-content: end;
}

.left_product_section,
.middle_product_section,
.last_product_section {
    position: relative;
    width: 100%;
    height: 100%;
}

.last_product_section {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.number_pro_se_lft,
.second_info_pro,
.number_pro_se_lft .plus-heading,
.number_pro_se_do .plus-heading {
    position: relative;
}

.number_pro_se_lft .plus-heading,
.number_pro_se_do .plus-heading {
    height: 60px;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.number_pro_se_lft .plus-heading {
    justify-content: end;
}

.number_pro_se_do .plus-heading {
    justify-content: start;
}

.number_pro_se_lft .plus-heading:before {
    position: absolute;
    bottom: 0;
    content: "";
    background: #b7ac50;
    height: 3px;
    width: 95%;
    left: 0rem;
}

.number_pro_se_lft::after {
    position: absolute;
    bottom: 0px;
    content: "";
    background: #b7ac50;
    height: 2.5px;
    width: 20%;
    left: -19.99%;
    transform: rotate(-25deg);
    transform-origin: right;
}


.number_pro_se_do .plus-heading:before {
    position: absolute;
    bottom: 0;
    content: "";
    background: #b7ac50;
    height: 3px;
    width: 95%;
    left: 5%;
}

.number_pro_se_do::after {
    position: absolute;
    bottom: 0px;
    content: "";
    background: #b7ac50;
    height: 2.5px;
    width: 20%;
    left: 99.80%;
    transform: rotate(-25deg);
    transform-origin: left;
}

.inner_top_info_second_info_pro {
    position: relative;
    top: 0rem;
}

/* .contact-box {
    background: #fff;
    padding: 40px;
    text-align: center;
    box-shadow: -4px 4px 25px -4px #cccccce0;
} */

.Product_menu_p_img img {
    text-align: center;
    background: #f1f1f1;
    width: 100%;
    height: 100%;
    border-radius: 0px;
}

.Product_menu_p_cont {
    padding-top: 10px;
    text-align: c;
}

.Product_menu_p_cont h5 {
    font-size: 25px;
    margin: 0;
    color: #fff;
    letter-spacing: 0;
    font-weight: 600;
}

.Product_menu_p_cont h6 {
    margin: 0;
    font-size: 14px;
}

.Product_menu_p {
    padding: 10px;
    border: 2px solid #b6ad53;
    border-radius: 0px;
    margin: 10px 0;
}

.Product_menu_p_W {
    padding: 10px;
    border: 2px solid #fff;
    border-radius: 0px;
    margin: 10px;
}

.Product_menu_p_cont_inner {
    padding-top: 5px;
    align-items: center;
}

.Product_menu_p_cont_inner a {
    margin: 0px;
    padding-top: 0px;
    color: #000;
    font-size: 14px;
}

.heading-container-second.our-story-head.heading-container p::after {
    background: #fff;
    top: 14px;
}

.heading-container-second.our-story-head.heading-container p::before {
    background: #fff;
    top: 14px;
}

.Product_menu_p_cont p {
    margin: 0;
    font-size: 14px;
    font-weight: bolder;
    letter-spacing: 0;
}

.Product_menu_p_cont_info {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.Product_menu_p_cont_info div span {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
}

.white-theme {
    color: #b7ac50 !important;
}

.related_articles_menu {
    padding: 10px;
    border-radius: 0px;
}

.related_articles_img img {
    text-align: center;
    background: #f1f1f1;
    width: 100%;
    height: 100%;
    border-radius: 0px;
}

.related_articles_info {
    text-align: right;
    padding-bottom: 10px;
    font-size: 14px;
}

.related_articles_info div span {
    letter-spacing: 0;
}

.related_articles_cont h5 {
    letter-spacing: 0;
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
}

.related_articles_cont p {
    font-size: 14px;
    letter-spacing: 0;
}

.related_articles_cont_link span {
    letter-spacing: 0;
    color: #000;
    margin: 0;
}

.accordion_outer .accordion__button {
    background-color: #ffffff !important;
    color: #000 !important;
    letter-spacing: 0;
}

.accordion_outer .accordion__panel {
    letter-spacing: 0;
    font-size: 14px;
}

.hero-slider a.section-menu-top-one-btn {
    display: inline-block;
    margin-bottom: 18px;
}

.section-menu-top-one+a {
    display: block;
}

.section-menu-top-one+a>img {
    width: 100%;
    height: 416px;
    object-fit: cover;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    display: none;
}

.swiper-button-prev,
.swiper-button-next {
    background-image: url('../images/arrowback.png') !important;
    background-size: 60% 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #ffffff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.swiper-button-next {
    transform: rotate(180deg);
}

@keyframes configure-clockwise {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes configure-xclockwise {
    0% {
        transform: rotate(45deg);
    }

    25% {
        transform: rotate(-45deg);
    }

    50% {
        transform: rotate(-135deg);
    }

    75% {
        transform: rotate(-225deg);
    }

    100% {
        transform: rotate(-315deg);
    }
}

.spinner-box {
    position: relative;
}

.load-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}


.configure-border-1 {
    width: 115px;
    height: 115px;
    padding: 2px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #256e2d;
    animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
}

.configure-border-2 {
    width: 115px;
    height: 115px;
    padding: 2px;
    left: -55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c0b665;
    transform: rotate(45deg);
    animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
}

.configure-core {
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 90%);
}

.product-details-1,
.product-details {
    align-self: center;
    overflow: hidden;
    padding: 0 15px !important;
}

.product-details-container .swiper {
    padding: 80px 0;
    transform: rotate(10deg);
    overflow: unset;
}

.product-details-container .swiper-slide>.item,
.product-details-container .swiper-pagination {
    transform: rotate(-10deg);
}

.swiper-pagination-bullet-active {
    background-color: #212529;
}

.stepbox {
    max-width: 820px;
    margin: auto;
    margin-bottom: 45px;
    position: relative;
}

.stepbox::after {
    content: '';
    height: calc(100% - 0px);
    width: 1px;
    background-color: #555;
    position: absolute;
    left: -28px;
    top: 35px;
}

.stepbox .number {
    position: absolute;
    left: -40px;
    width: 25px;
    height: 25px;
    background: #000;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 13px;
    opacity: 0.4;
}

.stepbox .title {
    opacity: 0.6;
    font-size: 14px;
    padding-top: 3px;
}

.current .number,
.current .title,
.commpleted .title {
    opacity: 1;
}

.commpleted .number {
    background-color: #b6ad53;
    opacity: 1;
}

.stepbox:last-child::after {
    display: none;
}

.form-check {
    padding-left: 40px;
}

.form-check .form-check-input {
    margin-left: -40px;
    width: 20px;
    height: 20px;
    padding: 0px !important;
}

.smallloading {
    height: 0;
    width: 0;
    padding: 9px;
    border: 4px solid #ccc;
    border-right-color: #888;
    border-radius: 22px;
    -webkit-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.postion-block-fixed {
    display: none;
}


@-webkit-keyframes rotate {

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes rotate {

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

/* --------------------09-04-24 (Ray Changes)----------------------- */

.main-top-wrapper {
    padding: 25px;
}

.main-top-wrapper::before {
    width: 100% !important;
    height: 100%;
}

.section-menu-top-one+a>img {
    height: 360px;
}

.hrrr .heading-container p::after,
.hrrr .heading-container p::before {
    width: 8%;
}

.hrrr p::after {
    right: -4% !important;
}

.hrrr p::before {
    left: -4% !important;
}