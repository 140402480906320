@media (min-width: 1800px) {

    .mbh-700px {
        max-width: 312px;
        height: 521px;
    }

    .postion-block-fixed h3 {
        top: -35px;
        right: -86px !important;
    }

    .section-menu-bottom-one .owl-carousel .owl-item img {
        height: 416px !important;
    }

    /* .main-top-wrapper::before {
        height: 708px !important;
    }

    .main-top-wrapper {
        padding: 25px 60px 25px 23px !important;
    } */

    .section-one-inner .col-lg-8,
    .section-one-inner .col-lg-4 {
        align-self: center;
    }

    .product-mobile-cont {
        width: 69.5%;
        height: 76%;
        left: 43px !important;
        top: 5.3rem !important;
        /* overflow: hidden; */
    }

    .section-menu-top-second h3 {
        letter-spacing: 3px;
        left: 28px;
        top: 100px;
    }

    .section-menu-top-second p {
        left: 33px;
        top: 90px;
        margin-bottom: 60px;
    }

    .mobile-slider {
        left: 0%;
    }

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1500px;
    }
}

@media (min-width: 1200px) {
    .ps-xl-150 {
        padding-left: 80px;
    }

    .ps-xl-100 {
        padding-left: 100px;
    }

    .p-5 {
        padding: 2.5rem !important;
    }
}

@media (max-width: 1400px) {
    .heading-container {
        text-align: center;
        max-width: 510px;
        position: relative;
    }

    .product-mobile-cont {
        /* left: 46px; */
        top: 7.1rem;
    }

    .mobile-slider-carousel.owl-carousel .owl-item img {
        width: 100%;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav {
        right: -10px;
    }

    .product-main-head.heading-container p::before {
        left: -15%;
        width: 10%;
    }

    .product-main-head.heading-container p::after {
        right: -40%;
        width: 35%;
    }

    .preroll-head.heading-container p::before {
        left: -8%;
        width: 5%;
    }

    .preroll-head.heading-container p::after {
        right: -45%;
        width: 40%;
    }

    .product-details .product-cart {
        width: 270px;
        height: 340px;
    }

    .mobile-slider {
        width: 89%;
    }

    .product-mobile-cont {
        width: 65.5%;
        left: 61px;
    }

    .product-rounded {
        width: 200px;
        height: 200px;
        padding: 9px;
    }

    .contact-head.heading-container p::after {
        right: -138%;
        width: 135%;
    }

    .ps-xl-150 {
        padding-left: 85px;
    }

    .cultivars.heading-container p::after {
        right: -87%;
        width: 82%;
    }

    .cultivars.heading-container p::before {
        left: -360%;
        width: 355%;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav .owl-next {
        right: -7%;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav .owl-prev {
        left: -7%;
    }

    .mt-n20 {
        margin-top: -15%;
    }

    .cultivars-cont::after {
        height: 162%;
        top: 22.2%;
    }

    .who-we.heading-container p::before {
        left: -60%;
        width: 55%;
    }

    .our-misstion-head.heading-container p::before {
        left: -48%;
        width: 42%;
    }

    .get-touch.heading-container p::before {
        left: -110%;
        width: 105%;
    }


    .export-touch.heading-container p::before {
        width: 34%;
        left: -39%;
    }

    .export-touch.heading-container p::after {
        right: -19.48%;
        width: 14%;
    }

    .who-we.heading-container::before {
        width: 18%;
    }

    .heading-container.our-misstion-head::before {
        left: -10.7%;
    }

    .hero-header {
        max-width: 100%;
        text-align: left;
    }

}

@media (max-width: 1200px) {
    .about-background::after {
        height: 61.2%;
    }

    .responsive-bg {
        text-align: center;
    }

    .welcome-head.heading-container p::after,
    .welcome-head.heading-container p::before {
        width: 15%;
    }

    .welcome-head.heading-container p::after {
        right: -18%;
    }

    .main-content.hero-content {
        padding: 85px 0 50px;
    }

    .contact-head.heading-container p::after {
        right: -20%;
        width: 15%;
    }

    .get-touch.heading-container p::before {
        left: -111%;
        width: 100%;
    }

    .main-top-wrapper::before {
        height: 633.5px;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav .owl-next {
        right: -9%;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav .owl-prev {
        left: -9%;
    }

    .mobile-slider {
        width: 98%;
    }

    .medical-background {
        display: none;
    }

    .postion-block-fixed h3 {
        font-size: 90px;
        top: 18px;
    }

    .maple-leaf {
        left: 31.5%;
        top: 26%;
    }

    .section-menu-top-second h3 {
        font-size: 90px;
    }

    .section-menu-top-second p {
        font-size: 55px;
    }

    .postion-block-fixed,
    .postion-block-fixed::after {
        width: 97%;
    }

    span.postion-block-fixed-in::after {
        left: -3px;
    }

    span.postion-block-fixed-in:before {
        display: none;
    }

    .menu-icons {
        left: 0px;
        right: unset;
    }

    .section-menu-top-second .menu-icons {
        bottom: 7.5%;

    }

    .center-content-carticars {
        top: 113%;
        max-width: 229px;
    }

    .product-mobile-cont {
        left: 45px;
        top: 6.32rem;
        height: 66%;
        width: 69%;
    }

    .mobile-slider-carousel.owl-carousel .owl-item img {
        width: 100%;
    }

    .section-menu-bottom-one .owl-carousel .owl-item img {
        height: 356px;
    }

    .medical-background-responsive {
        background-image: url("../images/medical-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 20px;
    }

    .owl-product-details-carousel .owl-nav,
    .product-details .owl-nav {
        text-align: center;
    }

    .main-content {
        padding: 0;
    }

    .welcome-head.mb-100 {
        margin-bottom: 40px !important;
    }

    .our-misstion-head.heading-container p::after,
    .get-touch.heading-container p::after {
        right: -40%;
        width: 35%;
    }

    .export-touch-bg {
        width: 40%;
    }

    .mt-n20 {
        margin-top: -8%;
    }

    .heading-container.contact-head {
        max-width: 100% !important;
    }

    .cultivars.heading-container p::before {
        left: -316.8%;
        width: 312%;
    }

    .cultivars-cont::after {
        top: 26%;
        height: 200%;
    }

    .our-misstion-head.heading-container p::before {
        left: -31% !important;
        width: 16% !important;
    }

    .about-background.col-xl-6 {
        display: none;
    }

    .about-background-responsive,
    .welcome-title,
    .contact-title {
        text-align: center;
        padding: 0 30px;
    }

    .footer-logo {
        width: 250px;
    }

    .who-we.heading-container p::before,
    .who-we.heading-container p::after,
    .export-touch.heading-container p::before,
    .export-touch.heading-container p::after,
    .our-misstion-head.heading-container p::before,
    .our-misstion-head.heading-container p::after {
        width: 35%;
    }

    .who-we.heading-container p::before,
    .export-touch.heading-container p::before,
    .our-misstion-head.heading-container p::before {
        left: -40%;
    }

    .who-we.heading-container p::after,
    .export-touch.heading-container p::after,
    .our-misstion-head.heading-container p::after {
        right: -45%;
    }

    .about-background-responsive {
        background-image: url("../images/about-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }

    .about-background-responsive::before {
        position: absolute;
        content: "";
        background-image: linear-gradient(45deg, #b7ac5080, #ffffff8c);
        inset: 0 0 0 0;
    }

    .welcome-head.heading-container p,
    .contact-head.heading-container p {
        background: transparent;
    }

    .welcome-title::after,
    .welcome-title::before,
    .contact-title::after,
    .contact-title::before,
    .who-we.heading-container::after,
    .who-we.heading-container::before,
    .heading-container.export-touch::before,
    .heading-container.export-touch::after {
        display: none;
    }

    .verical-divider {
        border-left: unset;
    }

    .responsive-bg {
        background-color: #ffffffab;
        padding: 30px;
        max-width: 800px;
        margin: auto;
    }

    .contact-bg {
        display: none;
    }

    .footer .ps-xl-150 {
        padding-left: 16px;
    }

    .contact-bg-one-section {
        background-image: url("../images/cannabis-trees.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }

    .contact-bg-one-section::before {
        position: absolute;
        content: "";
        background-image: linear-gradient(45deg, #b7ac5080, #ffffff8c);
        inset: 0 0 0 0;
    }
}

@media (max-width: 992px) {

    .get-touch.heading-container p::before {
        left: -37%;
        width: 32%;
    }


    .owl-right-slider .owl-nav {
        display: block;
    }


    .bg-light {
        background-color: unset !important;
    }

    .cultivars-img {
        margin-top: unset !important;
    }

    .cultivars-img.second {
        display: none;
    }

    .h2,
    h2 {
        font-size: 2.3rem;
    }

    .header-logo {
        width: 180px;
    }

    header {
        height: 70px;
    }

    .section-menu-top-second h3 {
        text-align: center;
        letter-spacing: unset;
        left: 0;
    }

    .products-section .heading-container,
    .product-details-container .heading-container {
        margin: 0 auto 20px;
    }

    button.owl-prev {
        margin-right: 12px !important;
    }

    .section-menu-top-one {
        max-width: 78%;
    }

    .section-menu-top-second p {
        width: unset;
        left: 0;
        text-align: center;
    }

    .center-content-carticars {
        position: relative;
    }

    .cultivars-img {
        margin-top: 50px;
    }

    .postion-block-fixed h3 {
        display: none;
    }

    .postion-block-fixed {
        display: none;
    }

    .about-pharm {
        background: unset;
    }

    .menu-icons ul li div {
        font-size: small;
    }

    .section-menu-top-second h3 span {
        display: inline-block;
    }

    .main-top-wrapper::before {
        height: 100%;
    }

    .product-mobile-cont {
        height: 82%;
        top: 11.32rem;
        left: 93px;
    }

    .mobile-slider-carousel.owl-carousel .owl-item img {
        width: 100%;
    }

    .section-one-inner {
        padding: 50px 0 50px;
    }

    .main-top-wrapper::before {
        width: 100%;
    }

    .main-top-wrapper {
        padding: 25px;
    }

    .postion-block-fixed {
        right: -19px;
        width: 25rem;
        height: 37rem;
        bottom: -40px;
    }

    span.postion-block-fixed-in:before,
    span.postion-block-fixed-in::after {
        display: none;
    }

    .menu-icons ul li {
        height: 30px;
        width: 30px;
        margin: 0 6px;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav {
        right: -9px;
        bottom: -47px;
        margin: 0 6px;
    }

    .menu-icons {
        right: unset;
        bottom: -47px;
        left: -12px;
    }

    .postion-block-fixed:before {
        height: 80.5%;
        top: 114px;
    }

    .postion-block-fixed::after {
        bottom: 0;
        width: 95%;
    }

    .navbar-toggler {
        font-size: 14px;
        padding: 5px 8px;
    }

    .ps-xl-150 {
        padding-left: 0;
    }

    .responsive-bg.ps-xl-150 {
        padding-left: 30px;
    }

    p {
        font-size: 0.82rem;
    }

    .section-content {
        padding: 45px 0;
    }

    .main-product {
        padding-top: 100px;
    }

    .h-800px {
        height: auto !important;
    }

    /* .our-story-img {
      width: 300px;
    } */

    .our-misstion-img {
        width: 400px;
    }

    .cultivars.heading-container p::after {
        right: -55%;
        width: 50%;
    }

    .cultivars.heading-container p::before {
        left: -55%;
        width: 50%;
    }

    .heading-container.cultivars::before,
    .cultivars-cont::after {
        display: none;
    }

    .cultivars-cont::after {
        top: 28%;
        height: 200%;
    }

    .our-misstion-head {
        margin: auto;
    }

    .mt-n20 {
        margin-top: 0;
    }

    .contact-info-box {
        margin-left: 3%;
    }


    .header {
        background-color: #d2ca90;
    }

    .header-container {
        background: #d2ca90;
    }

    .navbar-nav {
        height: calc(100vh - 65px);
    }


    .product-details-1 .owl-stage-outer,
    .product-details .owl-stage-outer {
        padding: 32px 0;
    }

    .product-details .owl-carousel,
    .product-details-1 .owl-carousel {
        padding: 0 !important;
    }

    .owl-carousel .owl-nav button.owl-next::after,
    .owl-carousel .owl-nav button.owl-prev::after {
        margin: 0 0px 0;
        width: 30px;
        height: 30px;
    }

    .heading-container.our-misstion-head::before {
        display: none;
    }

    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next,
    .owl-carousel button.owl-dot {
        width: 30px;
        height: 30px;
    }

    .heading-container.get-touch::before {
        display: none;
    }

    .heading-container.get-touch {
        margin: auto;
    }

    .number_pro_se_lft::after {
        display: none;
    }

    .number_pro_se_lft .plus-heading:before {
        width: 65%;
        left: 30%;
    }

    .product-flip-card {
        width: 240px;
        height: 320px;
        perspective: 1000px;
        margin: auto;
    }
}

@media (max-width: 767px) {

    /* .verifiction-bg.vh-100 {
      height: 100vh !important;
    }
    .vh-100 {
      height: 100% !important;
    } */

    .section-menu-top-second h3 {
        font-size: 75px;
        letter-spacing: 3px;
    }

    .section-menu-top-second p {
        font-size: 55px;
    }

    .product-mobile-cont {
        top: 9rem;
        left: 68px;
        height: 61%;
    }


    .mbh-700px {
        height: 500px;
    }

    .section-menu-bottom-one .owl-carousel .owl-item img {
        height: 240px;
    }

    .main-top-wrapper::before {
        height: 100%;
    }

    .section-menu-top-one {
        max-width: 100%;
    }

    .postion-block-fixed {
        height: 28rem;
    }

    .postion-block-fixed:before {
        height: 74.5%;
    }

    .cultivars-containter img {
        max-width: 80%;
    }

    .build-relationships-head p {
        margin: auto !important;
    }

    .contact-info-box {
        margin-left: 0%;
    }

    .build-relationships-head p::after,
    .build-relationships-head p::before {
        width: 15% !important;
    }

    .build-relationships-head p::after {
        right: -18%;
    }

    .mb-50 {
        margin-bottom: 25px;
    }

    .get-birth-container {
        padding: 20px;
    }

    .get-birth-container h2.h2 {
        font-size: 28px;
        margin-bottom: 10px;
    }

    .logo {
        width: 190px;
    }

    .flowerlist-head.heading-container p::after {
        right: -40%;
        width: 38%;
    }

    .number_pro_se_do::after {
        display: none;
    }

    .number_pro_se_do .plus-heading:before {
        width: 65%;
    }

    .product-main-img {
        max-width: 40%;
    }

    .number_pro_se span,
    .number_pro_se_lft span,
    .number_pro_se_do span {
        height: 35px;
        width: 35px;
        font-size: 18px;
    }

    .number_pro_se h4,
    .number_pro_se_do h4 {
        padding-left: 3%;
    }

    .number_pro_se_do .plus-heading:before {
        left: 3%;
    }

    .number_pro_se_lft h4 {
        padding-right: 3%;
    }

    .number_pro_se_lft .plus-heading:before {
        left: 32%;
    }

    .preroll-head.heading-container p::after {
        right: -8%;
        width: 5%;
    }

    .treatment-box .step,
    .treatment-box .step-icon {
        position: relative;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;
    }

    .treatment-box .step-info::after {
        display: none;
    }

    .treatment-box {
        height: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .treatment-box .step-info {
        padding-left: unset !important;
        padding-right: unset !important;
    }

    .treatment-box .step-icon {
        width: 40px;
        height: 40px;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .btn-dark,
    .btn-primary {
        font-size: small;
        padding: 8px 30px;
    }

    .contact-bg-two-section h1 {
        font-size: 2.5rem;
    }

    .responsive-bg {
        padding: 20px !important;
    }

    .heading-container.cultivars::before,
    .cultivars-cont::after {
        display: none;
    }

    .cultivars.heading-container p::before {
        left: -25%;
        width: 20%;
    }

    .cultivars.heading-container p::after {
        right: -25%;
        width: 20%;
    }

    .our-story-head.heading-container p::after {
        right: -45%;
        width: 40%;
    }

    .hero-header h2 {
        font-size: 26px;
    }

    .hero-header p {
        margin-left: 30px;
        font-size: 16px;
    }
}

@media (max-width: 576px) {

    .h1,
    h1 {
        font-size: 1.8rem;
    }

    .h2,
    h2 {
        font-size: 1.5rem;
    }

    .h3,
    h3 {
        font-size: 1.2rem;
    }

    .h5,
    h5 {
        font-size: 14px;
    }

    .h6,
    h6 {
        font-size: 13px;
    }

    .contact-form-section {
        padding: 40px 00;
    }

    p {
        font-size: 12px;
    }

    .get-birth-container h2.h2 {
        font-size: 24px;
    }

    .mb-50 {
        margin-bottom: 25px;
    }

    .pt-100 {
        padding-top: 50px;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav button.owl-prev::after,
    .section-menu-bottom-one .owl-carousel .owl-nav button.owl-next::after {
        display: none !important;
    }


    .heading-container {
        text-align: center !important;
    }

    input,
    optgroup,
    select,
    textarea {
        padding: 8px 10px !important;
    }

    * {
        line-height: 1.5;
        letter-spacing: 1px;
    }

    .build-relationships-head.heading-container p::after,
    .build-relationships-head.heading-container p::before {
        display: none;
    }

    .section-menu-top-second h3 {
        font-size: 45px;
    }

    .section-menu-top-second p {
        font-size: 40px;
        margin-top: 15px;
    }

    .product-mobile-cont {
        width: 162px;
        top: 94px;
        left: calc(50% - 84.5px);
    }

    .mobile-slider {
        width: 233px;
    }

    .mbh-700px {
        height: 250px;
    }

    .section-menu-bottom-one .owl-carousel .owl-item img {
        height: 210px;
    }

    .main-top-wrapper::before {
        height: 100%;
    }

    /* .section-one-inner {
        padding: 0;
    } */

    .hero-header p {
        margin: auto;
    }

    .main-top-wrapper::before {
        border-radius: 12px;

    }

    span.postion-block-fixed-in:before,
    span.postion-block-fixed-in::after {
        display: none;
    }


    .postion-block-fixed {
        display: none;
    }

    .menu-icons {
        display: none;
        left: 0;
        bottom: -42px;
        right: unset;
    }

    .postion-block-fixed:before {
        width: 2px;
        top: 98px;
    }

    a.section-menu-top-one-btn {
        padding: 6px 15px;
        font-size: smaller;
    }

    .main-top-wrapper {
        padding: 15px;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav {
        bottom: -60px;
        right: calc(50% - 42px);
    }

    .maple-leaf {
        left: 31.5% !important;
        top: 32% !important;
        font-size: 15px !important;
    }

    .contact-bg-two-section h1 {
        font-size: 1.8rem;
    }

    .pb-5 {
        padding-bottom: 1.5rem !important;
    }

    .mb-5 {
        margin-bottom: 1.5rem !important;
    }

    .mt-5 {
        margin-top: 1.5rem !important;
    }

    .p-5 {
        padding: 1.5rem !important;
    }

    .heading-container p {
        text-align: center;
    }

    .hero-content.main-content {
        padding: 45px 0 50px;
    }

    .pt-5 {
        padding-top: 2rem !important;
    }

    .py-4 {
        padding-top: 0.8rem !important;
        padding-bottom: 0.8rem !important;
    }

    .heading-container p::after,
    .heading-container p::before {
        /* display: none; */
        height: 2px;
        width: 15% !important;
        top: 49% !important;
    }

    .heading-container p::after {
        right: -18% !important;
    }

    .heading-container p::before {
        left: -18% !important;
    }

    .about-pharm {
        background: none;
        text-align: center;
    }

    .welcome-head.heading-container p::after,
    .welcome-head.heading-container p::before,
    .contact-head.heading-container p::after,
    .contact-head.heading-container p::before,
    .cultivars.heading-container p::after,
    .cultivars.heading-container p::before,
    .our-story-head.heading-container p::after,
    .our-story-head.heading-container p::before,
    .who-we.heading-container p::after,
    .who-we.heading-container p::before,
    .export-touch.heading-container p::after,
    .export-touch.heading-container p::before,
    .our-misstion-head.heading-container p::after,
    .our-misstion-head.heading-container p::before,
    .get-touch.heading-container p::after,
    .get-touch.heading-container p::before {
        top: 7px;
    }

    .who-we.heading-container p::after,
    .export-touch.heading-container p::after,
    .our-misstion-head.heading-container p::after,
    .cultivars.heading-container p::after {
        right: -15%;
    }

    .who-we.heading-container p::before,
    .who-we.heading-container p::after,
    .export-touch.heading-container p::before,
    .export-touch.heading-container p::after,
    .our-misstion-head.heading-container p::before,
    .our-misstion-head.heading-container p::after,
    .cultivars.heading-container p::after,
    .cultivars.heading-container p::before {
        width: 15%;
    }

    .who-we.heading-container p::before,
    .export-touch.heading-container p::before,
    .our-misstion-head.heading-container p::before,
    .cultivars.heading-container p::before {
        left: -15%;
    }

    .our-story-head.heading-container p::after {
        right: -35%;
        width: 30%;
    }

    .section-menu-bottom-one .owl-carousel .owl-item img {
        margin-top: 25px;
    }

    .export-touch-1-img,
    .export-touch-2-img {
        width: 90px;
    }

    .export-touch-1-img {
        right: -45px !important;
    }

    .export-touch-2-img {
        left: -45px !important;
    }

    .export-touch-bg {
        width: 60%;
        height: 230px;
    }

    .responsive-bg {
        padding: 12px !important;
    }

    .contact-box {
        padding: 15px;
    }

    .about-background-responsive,
    .welcome-title,
    .contact-title {
        padding: 0 15px;
    }

    .medical-step .medical-program img {
        width: 25px;
    }

    .medical-step .medical-program img:nth-child(1) {
        width: 20px;
    }
}

@media (max-width: 370px) {
    .heading-container p::after {
        right: -5% !important;
    }

    .heading-container p::before {
        left: -5% !important;
    }

    .heading-container p::after,
    .heading-container p::before {
        width: 5% !important;
    }

    .cultivars.heading-container p::after,
    .cultivars.heading-container p::before {
        width: 10% !important;
    }

    .cultivars.heading-container p::after {
        right: -10% !important;
    }

    .cultivars.heading-container p::before {
        left: -10% !important;
    }

    .section-menu-top-second h3 {
        font-size: 37px;
        letter-spacing: 6px;
        left: 0;
    }

    .section-menu-top-second p {
        font-size: 32px;
        margin-top: 15px;
    }


    .section-menu-top-one {
        max-width: 100%;
    }

    .main-top-wrapper::before {
        height: 100%;
    }



    .postion-block-fixed:before {
        top: 89px;
    }

    .maple-leaf {
        font-size: 12px !important;
    }

    .welcome-head.heading-container p::after,
    .welcome-head.heading-container p::before,
    .contact-head.heading-container p::after,
    .contact-head.heading-container p::before,
    .cultivars.heading-container p::after,
    .cultivars.heading-container p::before,
    .our-story-head.heading-container p::after,
    .our-story-head.heading-container p::before,
    .medical-step-head.heading-container p::after,
    .medical-step-head.heading-container p::before,
    .preroll-section.heading-container p::after,
    .preroll-section.heading-container p::before,
    .product-main-head.heading-container p::after,
    .product-main-head.heading-container p::before,
    .preroll-head.heading-container p::after,
    .preroll-head.heading-container p::before,
    .who-we.heading-container p::after,
    .who-we.heading-container p::before,
    .export-touch.heading-container p::after,
    .export-touch.heading-container p::before,
    .our-misstion-head.heading-container p::after,
    .our-misstion-head.heading-container p::before,
    .get-touch.heading-container p::after,
    .get-touch.heading-container p::before,
    .products-section.heading-container p::after,
    .products-section.heading-container p::before,
    .flowerlist-head.heading-container p::after,
    .flowerlist-head.heading-container p::before {
        top: 50%;
    }

}

@media (min-width: 1800px) {
    .h-800px {
        height: 100vh;
        min-height: 1030px;
    }

    .main-bg .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mobile-slider-carousel.owl-carousel .owl-item img {
        width: 100%;
    }
}