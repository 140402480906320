@font-face {
    font-family: 'Neuropol';
    font-style: normal;
    font-weight: normal;
    src: local('Neuropol'), url('../fonts/NEUROPOL.woff') format('woff');
}

body,
#root {
    overflow-x: hidden;
}


.postion-block-fixed h3,
.section-menu-top-second h3,
.section-menu-top-second p {
    font-family: 'Neuropol';
}

.section-menu-top-second p {
    text-transform: uppercase;
    left: 110px;
    font-size: 50px;
}

.section-menu-top-second {
    margin: auto;

}

.main-second-wrapper * {
    line-height: 1.2;
}

.section-menu-top-second h3 {
    font-size: 115px;
    left: 99px;
}

.postion-block-fixed h3 {
    font-size: 115px;
    right: -147px;
}

.maple-leaf {
    left: 31.7%;
    top: 34%;
    font-size: 31px;
}

.maple-leaf svg {
    background-color: #ffffff;
}

.postion-block-fixed {
    width: 23rem;
}

.medical-step-form .MuiStepLabel-iconContainer .Mui-active {
    color: #000000;

}

.medical-step-form .MuiStepLabel-iconContainer .Mui-completed,
.MuiDayCalendar-header button.MuiPickersDay-root.Mui-selected {
    color: #b6ab4f !important;
}

.postion-block-fixed:before {
    top: 0;
    height: 96%;
}

.main-top-wrapper::before {
    width: 96.3%;
}

.section-menu-top-second h3 span {
    display: inline-block;
    font-family: 'Neuropol';

}

.h2,
h2,
.h1,
h1,
h2 span {
    font-family: 'Neuropol';
    text-transform: uppercase;
}

.section-menu-top-second h3>span {

    background-color: #c0b665;
}

.section-menu-top-second h3 {
    position: relative;
    z-index: 1;
    white-space: nowrap;
    left: -284px;
}

.who-we.heading-container::after {
    left: 1px;
    top: -80px;
    height: 164%;
}

.who-we p.main-bg::after,
.who-we p.main-bg::before {
    display: none;
}

.who-we.heading-container::before {
    left: 1px;
    width: 80%;
}

.cultivars-cont::after {
    display: none;
}

.heading-container.our-misstion-head::before {
    left: 0.8%;
}

.center-content-carticars {
    max-width: 468px;
}

.cultivars.heading-container p::before {
    left: -160%;
    width: 150%;
}

.heading-container.cultivars::before {
    height: 182%;
    top: -95.3%;
}

.our-misstion-head.heading-container p::after {
    right: -155%;
    width: 150%;
}

.heading-container.export-touch::before {
    height: 168.5%;
    top: -80%;
}

.heading-container.export-touch::after {
    top: 85.5%;
}

.heading-container.cultivars::before {
    height: 178%;
    top: -91.3%;
}

.section-content {
    overflow: hidden;
}

.cultivars-img.one {
    z-index: 1;
    position: relative;
}

.treatment-box .step.step-4 {
    top: -2.0%;
}

.treatment-box .step.step-3 {
    top: 7%;
    left: 21%;
}

.treatment-box .step.step-2 {
    bottom: 44%;
}

.treatment-box .step.step-1 {
    bottom: 20%;
}

.heading-container.contact-head {
    padding-left: 90px;
}

.product-main-head {
    padding-left: 80px !important;
}

.product-main-head.heading-container p::after {
    right: -105%;
    width: 100%;
}

.heading-container.who-we {
    max-width: 87.2%;
}

.our-misstion-head.heading-container p::before {
    left: -50%;
    width: 43%;
}

.product .row.h-100 {
    padding-top: calc(110px - 3rem);
    padding-bottom: calc(110px - 3rem);
}


@media only screen and (min-width: 1441px) {

    .our-misstion-head.heading-container p::before {
        left: -64%;
        width: 59%;
    }

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1500px;
    }
}

@media only screen and (min-width: 1200px) {

    .about-background-responsive .pt-100,
    .medical-row .col-xl-6,
    .contact-bg-two-section .container {
        padding-top: 150px !important;
        padding-bottom: 70px !important;
    }

}


@media (min-width: 1401px) and (max-width: 1441px) {
    .contact-head.heading-container p::after {
        right: -92%;
        width: 90%;
    }

    .postion-block-fixed {
        right: 10px;
    }
}

@media (max-width: 1200px) {
    .section-menu-top-second h3 {
        left: -224px;
        font-size: 80px;
    }

    .section-menu-top-second p {
        left: 48px;
        font-size: 35px;
    }

    .postion-block-fixed {
        right: 1px;
    }

    .maple-leaf {
        font-size: 22px;
    }

    .medical-step-img {
        display: none;
    }

    .postion-block-fixed:before {
        top: 96px;
        height: 80%;
    }

    .postion-block-fixed {
        height: 38rem;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav .owl-next {
        right: -11%;
    }

    .about-background-responsive {
        padding-top: 120px;
        padding-bottom: 40px;
    }

    .mt-n20 {
        margin-top: -3%;
    }

    .cultivars-img.second {
        max-width: 200px;
    }

    .cultivars-img {
        max-width: 355px;
    }

    .miss-head {
        margin-top: 150px;
    }

    .our-misstion-head.heading-container p::before {
        left: -21% !important;
    }

    .medical-background-responsive {
        padding-top: 130px;
        padding-bottom: 50px;
    }

    .medical-row .col-xl-6 {
        padding-top: 55px;
    }

    .responsive-bg {
        backdrop-filter: blur(8px);
        background-color: #ffffff73;
    }

    .heading-container.contact-head {
        padding-left: unset;
    }

    .contact-bg-two-section .container {
        padding-top: 130px !important;
        padding-bottom: 50px;
    }
}

@media (max-width: 992px) {
    .section-menu-top-second h3 {
        left: 0;
        top: 20px;
    }

    .section-menu-top-second p {
        left: 0;
        top: 8px;
    }

    .main-top-wrapper::before {
        width: 100%;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav .owl-next {
        right: -6%;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav .owl-prev {
        left: -6%;
    }

    .product-rounded {
        display: none;
    }

    .about-pharm {
        margin-bottom: 50px;
    }

    .our-misstion-head.heading-container p::after {
        right: -22%;
        width: 18%;
    }

    .miss-head {
        margin-top: 10px;
    }

    .product-main-head {
        padding-left: 15px !important;
    }

    .product-main-head.heading-container p::after {
        right: -55%;
        width: 50%;
    }
}

@media (max-width: 576px) {
    .section-menu-top-second h3 {
        font-size: 46px;
        top: 35px;
    }

    .section-menu-top-second p {
        top: 17px;
        font-size: 26px;
    }

    .section-menu-bottom-one .owl-carousel .owl-nav .owl-next,
    .section-menu-bottom-one .owl-carousel .owl-nav .owl-prev {
        display: none;
    }

    .owl-carousel .owl-item img {
        object-fit: cover;
    }

    .product-flip-card {
        width: 250px;
        height: 320px;
    }

    .responsive-bg {
        padding: 18px !important;
    }

    .welcome-head.mb-100 {
        margin-bottom: 18px !important;
    }

    .welcome-head .heading-container p::before {
        left: -6% !important;
        width: 6% !important;
    }

    .heading-container p::after {
        right: -6% !important;
    }

    .product-name {
        padding: 235px 15px 15px;
    }

    .main-top-wrapper:before {
        height: 100%;
    }
}


/* --------- Medical stepper Form css (21-04-2023) ------------ */

.medical-step-form .heading-container h2 {
    font-size: 30px;
}

.medical-step-form .MuiInputBase-root {
    padding: 3px !important;
}


/* -------  25-04-2023(office) ------- */

/* input, optgroup, select, textarea {
    width: 100%;
    border: 1px solid #000;
}

label + .MuiFormControl-root {
    width: 100%;
    border: 1px solid #000;

} */
/* label+.MuiFormControl-root .MuiInputBase-root,
label+.MuiFormControl-root {
    padding: 0 !important;
} */

fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
}

input+.MuiInputAdornment-root {
    min-width: 40px;
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: #b7ac50;
}

.em-loader .MuiCircularProgress-root {
    color: #000000 !important;
    width: 25px !important;
    height: 25px !important;
}

label+.input-tag {
    position: relative;
}

.input-tag .em-loader {
    position: absolute;
    top: 50%;
    right: 28px;
    transform: translateY(-50%);
}

.btn:disabled {
    background-color: #484848;
}

/* 
.form-error {
    font-size: 12px;
    border-radius: 6px;
    background: #ff000017;
    border: 1px solid #f2adad;
    margin-top: 2px;
} */


.hero-header p::after,
.hero-header p::before {
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
}

.hero-header p::before {
    left: -10%;
}

.hero-header p::after {
    right: -20%;
}

.hero-header {
    margin-left: 0 !important;
    text-align: left;
    max-width: 900px;
}

.hero-header p {
    margin-left: 30px;
    font-size: 20px;
}

.hero-header h2 {
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    font-size: 30px;
}

.main-top-wrapper::before {
    background: #D2CA90;
    border: none;
    border-radius: 31px;
}

.section-menu-top-one+a {
    display: block;
    border-radius: 16px;
    overflow: hidden;
}

.location-map {
    margin-top: 20px;
    overflow: hidden;
    border-radius: 10px;
}

.location-map>iframe {
    display: block;
}

.hero-slider .swiper-button-prev,
.hero-slider .swiper-button-next {
    top: unset;
    bottom: 36%;
}

.plant-science-pro.heading-container p::before,
.plant-science-pro.heading-container p::after {
    background-color: #ffffff;
}

.prodact-packing>img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}