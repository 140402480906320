@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600;700;800&display=swap");

:root {
    --olive-green: #b7ac50;
    --black-color: #000000;
    --white-color: #ffffff;
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    line-height: 1.8;
    letter-spacing: 2px;
}

html,
body {
    margin: 0;
    padding: 0;
    position: relative;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.4;
    word-break: break-word;
}

.h1,
h1 {
    font-size: 3.5rem;
    font-weight: 700;
}

.h2,
h2 {
    font-size: 2.3rem;
    font-weight: 700;
}

.h3,
h3 {
    font-size: 1.5rem;
    font-weight: 700;
}

.h4,
h4 {
    font-size: 1.2rem;
    font-weight: 600;
}

p {
    font-weight: 500;
}

.mt-100 {
    margin-top: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-50 {
    padding-bottom: 50px;
}

.mt-n20 {
    margin-top: -14%;
}

.main-bg {
    background: #c0b665;
}

.text-main {
    color: #b7ac50;
}

.section-content {
    padding: 80px 0;
    position: relative;
}

.cartivars-p {
    position: relative;
}

.center-content-carticars {
    position: absolute;
    left: 0;
    top: 150%;
    max-width: 268px;
}

.owl-product-details-carousel .owl-stage-outer {
    padding: 90px 0;
}

.fixed-ncsd {
    height: 100%;
}


/* Heror .css */

.main-top-wrapper {
    padding: 25px 60px 26px 25px;
    position: relative;
    margin: 25px 0;
}

.section-menu-bottom-one .owl-carousel .owl-dots {
    display: none;
}

.section-menu-bottom-one .owl-carousel .owl-nav button.owl-prev::after,
.section-menu-bottom-one .owl-carousel .owl-nav button.owl-next::after {
    background: #c0b665;
}


button.owl-prev {
    position: relative;
    height: 40px;
    width: 40px;
    background: #fff !important;
    border-radius: 100%;
    margin-right: 20px !important;
}

button.owl-next {
    position: relative;
    height: 40px;
    width: 40px;
    background: #fff !important;
    border-radius: 100%;
}

.product-details-1 button.owl-prev::after,
.product-details-1 button.owl-next::after {
    background-color: #c0b665 !important;
}


/* .section-menu-bottom-one .owl-carousel .owl-nav {
  position: absolute;
  right: 0px;
  bottom: -58px;
} */

.section-menu-bottom-one .owl-carousel .owl-nav .owl-prev,
.section-menu-bottom-one .owl-carousel .owl-nav .owl-next {
    position: absolute;
    bottom: 27.5%;
}

.section-menu-bottom-one .owl-carousel .owl-nav .owl-prev {
    left: -6%;
}

.section-menu-bottom-one .owl-carousel .owl-nav .owl-next {
    right: -6%;
}

.postion-block-fixed {
    position: absolute;
    background: linear-gradient(309deg, #c0b665 72%, #ef060600 50%);
    height: 40.6rem;
    width: 33rem;
    content: "";
    bottom: -29px;
    right: 15px;
}

.main-top-wrapper::before {
    position: absolute;
    content: "";
    border: 3px solid #000000;
    height: 709.5px;
    top: 0;
    left: 0;
    width: 95.5%;
    z-index: 0;
}

a.section-menu-top-one-btn {
    padding: 13px 30px;
    background: #ffffff;
    text-decoration: none;
    color: #000000;
}

.section-menu-bottom-one .owl-carousel .owl-item img {
    height: 416px;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    margin-top: 40px;
}

.postion-block-fixed h3 {
    font-size: 110px;
    color: #fff;
    font-weight: 900;
    letter-spacing: 3px;
    position: absolute;
    top: -64px;
    right: -100px;
    background-color: #c0b665;
    z-index: 2;
}

.maple-leaf {
    color: #770303 !important;
    /* color: #000000; */
    /* color: #D80621; */
    position: absolute;
    left: 32.5%;
    top: 31%;
    font-size: 33px;
}


.section-menu-top-second h3 {
    font-size: 110px;
    letter-spacing: 3px;
    font-weight: 900;
    color: #000000;
    line-height: 1;
    position: relative;
    left: 42px;
    top: 70px;
}

.section-menu-top-second h3 span {
    color: #ffffff;
    display: none;
}

.section-menu-top-second p {
    font-size: 64px;
    margin-top: 0px;
    padding: 0;
    line-height: 1;
    color: #000000;
    position: relative;
    font-weight: 900;
    letter-spacing: 3px;
    left: 48px;
    top: 50px;
    width: 0;
}

.postion-block-fixed:before {
    position: absolute;
    right: 20px;
    top: 153px;
    content: "";
    width: 3px;
    background: #fff;
    height: 72.5%;
}

.postion-block-fixed::after {
    position: absolute;
    right: 20px;
    bottom: 25px;
    content: "";
    width: 97%;
    background: #fff;
    height: 3px;
}


span.postion-block-fixed-in:before {
    position: absolute;
    width: 25px;
    bottom: 75px;
    height: 3px;
    background: #fff;
    content: "";
    left: 0px;

    display: none;
}

span.postion-block-fixed-in::after {
    position: absolute;
    width: 3px;
    bottom: 25px;
    height: 50px;
    background: #fff;
    content: "";
    left: 22px;

    display: none;
}

.menu-icons {
    position: absolute;
    right: 16.5rem;
    bottom: -57px;
}

.section-menu-top-second .menu-icons {
    width: 100%;
    right: 1%;
    bottom: 0.5%;
    z-index: 2;
    /* display: none; */
}

.section-menu-top-second .menu-icons ul {
    padding: 0;
}

.section-menu-top-second .menu-icons ul li {
    margin: 0 2px;
    border-radius: 20%;
}

.main-content.product {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-icons ul {
    text-decoration: none;
    list-style: none;
}

.menu-icons ul li {
    display: inline-block;
    position: relative;
    border-radius: 100%;
    background: transparent;
    margin: 0 10px;
    height: 40px;
    width: 40px;
    overflow: hidden;
}

.menu-icons ul li div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: larger;
}

.product-mobile-cont {
    position: absolute;
    background: #000;
    width: 69.5%;
    height: 70%;
    left: 54px;
    top: 6.3rem;
    border-radius: 1px;
    overflow: hidden;
}

/* .product-mobile-cont {
  width: 59.5%;
  left: 82px;
  top: 2.3rem;
  height: 91.5%;
  border-radius: 26px;
  overflow: hidden;
} */

.mobile-slider {
    z-index: 1;
    position: relative;
    top: 3%;
    left: 2%;
    width: 93%;
}

.text-ellipse {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobile-slider-carousel.owl-carousel .owl-item img {
    width: 100%;
}


.section-menu-top-one h3 {
    font-weight: 700;
}

.section-menu-top-one {
    padding-left: 0px;
    max-width: 100%;
}

header {
    position: fixed;
    top: 0;
    height: 82px;
    width: 100%;
    z-index: 1000000;
    transition: 0.5s;
    background: #ffffff47;
    box-shadow: 0 -28px 50px #201f1dcf;
    backdrop-filter: blur(15px);
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    border-bottom: 3px solid #275b2d;
}

.fixed-navbar {
    background: #ffffff8d;
    box-shadow: 0 -28px 50px #201f1dcf;
}

.h-800px {
    /* height: 800px; */
    min-height: 100vh;
}

.mbh-700px {
    height: 550px;
    /* height: 700px; */
    /* height: 100%; */
    max-width: 330px;
}

.main-bg.hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 70px 0 0px !important;
}


/* .mobile-slider {
  width: 72%;
} */

.age-verify .input-box {
    position: relative;
}

.age-verify .input-box .form-error {
    left: 0;
    top: 100%;
    background: #ff000017;
}

.main-content {
    padding: 0px 0 0px;
    overflow: hidden;
}

.main-content.hero-content {
    padding: 70px 0 50px;
    background-color: #c0b665;
}

.social-media * {
    line-height: 1 !important;
}

.owl-left-carousel .owl-nav button.owl-next,
.owl-left-carousel .owl-nav button.owl-prev {
    background: #b6ad53;
}

.owl-carousel .owl-nav button.owl-next::after,
.owl-carousel .owl-nav button.owl-prev::after {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    font-size: larger;
    color: #ffff;
    position: absolute;
    inset: 0 0 0 0;
    z-index: -1;
    content: "";
}

.owl-carousel .owl-nav button.owl-next {
    background: url("../images/dark-right.png") no-repeat scroll 0px 0px !important;
    background-size: contain !important;
}

.about-pharm {
    overflow: hidden;
    background: url("../images/patternone.png") no-repeat;
    /* background: url("../images/patternone2.png") no-repeat; */
    background-size: contain !important;
    background-position: 106% -75px;
}

.owl-carousel .owl-nav button.owl-prev {
    background: url("../images/dark-left.png") no-repeat !important;
    background-size: contain !important;
}

.cannjah-care {
    position: relative;
}

.cannjah-care::after {
    position: absolute;
    content: "";
    width: 30%;
    height: 3px;
    background-color: #b7ac50;
    border-radius: 10px;
    border-bottom: 3px solid #b7ac50;
    bottom: -40%;
    left: 9%;
}

.Accepting-Patients {
    overflow: hidden;
    background: url("../images/medical-doctor-holding-cannabis-leaf-bottle-cannabis-oil-white-wall.png") no-repeat;
    background-size: cover !important;
    background-attachment: fixed;
    background-position: center;
}

.build-relationships-head {
    max-width: 100% !important;
}

.build-relationships-head p {
    margin: 0px 15% !important;
}

.build-relationships-head p::after,
.build-relationships-head p::before {
    top: 50%;
    background: #ffffff;
}

.contact-info-box {
    display: inline-block;
    margin-left: 8%;
}

.extra-contact {
    margin-bottom: -9%;
}

a.text-dark {
    color: #000;
}

.heading-container {
    text-align: center;
    max-width: 600px;
    position: relative;
}

.heading-container p {
    position: relative;
    width: fit-content;
    margin: auto;
    padding: 0 8px;
    font-weight: 600;
}

.heading-container p::after,
.heading-container p::before {
    position: absolute;
    content: "";
    width: 15%;
    height: 3px;
}

.heading-container p::before {
    left: -20%;
}

.heading-container p::after {
    right: -31%;
    width: 28%;
}

.welcome-head.heading-container p::after,
.welcome-head.heading-container p::before,
.contact-head.heading-container p::after,
.contact-head.heading-container p::before,
.cultivars.heading-container p::after,
.cultivars.heading-container p::before,
.our-story-head.heading-container p::after,
.our-story-head.heading-container p::before,
.medical-step-head.heading-container p::after,
.medical-step-head.heading-container p::before,
.preroll-section.heading-container p::after,
.preroll-section.heading-container p::before,
.product-main-head.heading-container p::after,
.product-main-head.heading-container p::before,
.preroll-head.heading-container p::after,
.preroll-head.heading-container p::before {
    background: #b7ac50;
    top: 14px;
}

input[name="userAge"] {
    width: 100%;
}

.who-we.heading-container p::after,
.who-we.heading-container p::before,
.export-touch.heading-container p::after,
.export-touch.heading-container p::before,
.our-misstion-head.heading-container p::after,
.our-misstion-head.heading-container p::before,
.get-touch.heading-container p::after,
.get-touch.heading-container p::before,
.plant-science.heading-container p::after,
.plant-science.heading-container p::before,
.products-section.heading-container p::after,
.products-section.heading-container p::before,
.flowerlist-head.heading-container p::after,
.flowerlist-head.heading-container p::before,
.Alternative-head.heading-container p::after,
.Alternative-head.heading-container p::before {
    background: #ffffff;
    top: 14px;
}

.preroll-section.heading-container p::after {
    right: -24%;
    width: 18%;
}

.plant-science-table td,
.plant-science-table th {
    border: 1px solid rgb(0, 0, 0);
    padding: 10px 20px;
}

.plant-science-table table thead tr {
    background: #00000014;
}

.preroll-section.heading-container p::before {
    left: -25%;
}

.welcome-head.heading-container p,
.contact-head.heading-container p {
    background-color: #ffffff;
}

.btn-success {
    --bs-btn-bg: #b7ac50;
    --bs-btn-border-color: #b7ac50;
    --bs-btn-hover-bg: #988d38;
    --bs-btn-hover-border-color: #988d38;
    --bs-btn-disabled-bg: #988d38;
    --bs-btn-disabled-border-color: #988d38;
    --bs-btn-active-bg: #988d38;
    --bs-btn-active-border-color: #988d38;
}

.who-we.heading-container::after {
    position: absolute;
    top: -80px;
    left: -39px;
    content: "";
    background-color: #ffffff;
    width: 3px;
    height: 158%;
}

.owl-right-carousel .owl-nav {
    text-align: center;
}

.moreitem-carousel .owl-stage-outer {
    padding: 25px 0;
}

.view-more-box {
    position: relative;
    height: 100%;
}

.view-more-img {
    width: 80%;
    position: absolute;
    bottom: 25px;
    right: 0;
}

.export-touch-bg {
    background-image: url("../images/export-touch-1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 390px;
}

.our-story-bg {
    background-image: url("../images/our-sory.png");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f6f7f9;
}


.products-section.heading-container p::after {
    right: -20%;
    width: 15%;
}

.who-we.heading-container::before {
    position: absolute;
    bottom: 12px;
    left: -39px;
    content: "";
    background-color: #ffffff;
    width: 100%;
    height: 3px;
}

.vh-100 {
    min-height: 100vh;
    height: unset !important;
}

.heading-container.export-touch::after,
.heading-container.export-touch::before,
.heading-container.our-misstion-head::after,
.heading-container.our-misstion-head::before,
.heading-container.get-touch::after,
.heading-container.get-touch::before {
    position: absolute;
    content: "";
    background-color: #ffffff;
}

.heading-container.export-touch::after {
    width: 3px;
    height: 100%;
    right: 11%;
    top: 87.5%;
}

/* .navbar-expand-lg .navbar-nav {
  backdrop-filter: blur(4px);
} */

.cultivars-img {
    max-width: 438px;
    width: 100%;
    border: none;
    padding: 0;
    border-radius: 0;
}

.cultivars-img.second {
    max-width: 370px;
}

.heading-container.export-touch::before {
    width: 3px;
    height: 162.5%;
    left: 0;
    top: -73%;
}

.export-touch.heading-container p::before {
    width: 51%;
    left: -54%;
}

.heading-container.cultivars::after,
.heading-container.cultivars::before {
    position: absolute;
    content: "";
    background-color: #b7ac50;
}

.cultivars-cont::after {
    position: absolute;
    content: "";
    background-color: #b7ac50;
    width: 3px;
    height: 150%;
    left: -3.15%;
    top: 22.3%;
}

.heading-container.cultivars::before {
    width: 3px;
    height: 169%;
    right: 0;
    top: -81.3%;
}

.detail-info-background h2 {
    font-size: 32px;
}

.cultivars.heading-container p::after {
    right: -111%;
    width: 106%;
}

.cultivars.heading-container p::before {
    z-index: -1;
    left: -428%;
    width: 421%;
}

.our-story-head.heading-container p::before {
    left: -40%;
    width: 35%;
}

.our-story-head.heading-container p::after {
    right: -105%;
    width: 100%;
}

.preroll-head.heading-container p::before {
    left: -10%;
    width: 7%;
}

.preroll-head.heading-container p::after {
    right: -55%;
    width: 50%;
}

.product-main-head.heading-container p::before {
    left: -25%;
    width: 20%;
}

.product-main-head.heading-container p::after {
    right: -65%;
    width: 60%;
}


.flowerlist-head.heading-container p::before {
    left: -40%;
    width: 35%;
}

.flowerlist-head.heading-container p::after {
    right: -105%;
    width: 100%;
}

.flowerlist-head {
    max-width: 400px;
}

.medical-step-head.heading-container p::before {
    left: -20%;
    width: 15%;
}

.medical-step-head.heading-container p::after {
    right: -30%;
    width: 25%;
}

.heading-container.our-misstion-head::before,
.heading-container.get-touch::before {
    width: 3px;
    height: 167%;
    left: 0.2%;
    top: -79%;
}

.heading-container.our-misstion-head::before {
    left: -10.2%;
}

.our-misstion-head.heading-container p::before {
    left: -64%;
    width: 59%;
}

/* .main-product {
  background: url('../images/product-main-img (1).png') no-repeat;
  background-position-x: right;
  background-position-y: -90px;
} */

.get-touch.heading-container p::before {
    left: -139%;
    width: 130%;
}


.product-details .owl-carousel.owl-drag .owl-item {
    transform: rotate(10deg);
    transition: 0.5s;
}

.product-details .owl-stage-outer {
    padding: 61px 0;
    overflow: visible !important;
    transform: rotate(350deg) !important;
}

.product-details-1 .owl-stage-outer {
    padding: 61px 0;
    overflow: visible !important;
    transform: rotate(10deg) !important;
}

.product-details-1 .owl-carousel.owl-drag .owl-item {
    transform: rotate(350deg);
}

.product-details .owl-carousel .owl-item .card {
    max-width: 300px;
    margin: auto;
}

.product-details .owl-nav {
    text-align: end;
    padding: 0 7rem;
}

.product-details .owl-carousel.owl-drag .owl-item:hover {
    z-index: 10;
    transition: 0.5s;
}

.description-product tr {
    vertical-align: top;
}

.badge-container .badge {
    padding: 8px 15px;
    background-color: #eeecd7 !important;
    color: #b7ac50 !important;
    font-size: 15px;
    font-weight: 500;
}

.Section_onepad_own {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-main-img {
    width: 70%;
}

.product-container {
    max-width: 530px;
}

.main-product {
    padding-top: 150px;
}

.product-image {
    max-width: 530px;
    max-height: 530px;
    border: 3px solid #000;
    padding: 10px;
}

.product-image img {
    aspect-ratio: 1/1;
}

.product-details .owl-carousel,
.product-details-1 .owl-carousel {
    overflow: hidden;
}

.product-details .box {
    width: 100%;
    height: 100%;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    background-size: 120%;
    box-shadow: -1.5rem 1.5rem 2rem rgb(0 0 0 / 31%);
}

.product-details .product-cart {
    width: 320px;
    height: 400px;
    border: none;
    position: relative;
}

.product-details .hover-content {
    display: none;
}

.product-details .box:hover .hover-content {
    display: block;
}

.product-details .box:hover {
    box-shadow: 0px 0.5rem 2rem rgba(0, 0, 0, 0.75);
    background-position: 0% 40% !important;
}

.product-details .box .text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 10%;
    bottom: 7%;
    margin-top: -4rem;
    width: calc(20rem / 2);
    height: calc(6rem / 2);
    color: #fff;
    background-color: #b7ac5087;
    transform: rotate(-180deg) translate(-8) rotate(180deg);
    box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.5);
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.product-details .text span {
    font-size: 20px;
}

.product-details .box:hover .text {
    transform: rotate(-45deg) translate(0) rotate(-315deg);
    background: #b6ab50e0;
    width: 16rem;
    height: 16rem;
    left: calc(50% - 8rem);
    margin-top: -8rem;
    transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
}

.owl-carousel .owl-nav button.owl-next span,
.owl-carousel .owl-nav button.owl-prev span {
    display: none;
}

.owl-right-slider .owl-nav {
    display: none;
}

.product-rounded {
    width: 250px;
    height: 250px;
    margin: auto;
    border: 2px solid #000000;
    border-radius: 50%;
    padding: 15px;
    position: relative;
}

.product-image-container {
    border-radius: 50%;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.product-image-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* .product-rounded::before {
  content: "";
  position: absolute;
  background: black;
  width: 2px;
  height: 65px;
  bottom: -85px;
  left: calc(50% - 1px);
} */

.verical-divider {
    border-top: 2px dashed #b7ac50;
}

.our-misstion-head.heading-container p::after,
.get-touch.heading-container p::after {
    right: -55%;
    width: 50%;
}

/* .bg-dark {
  background-color: #000 !important;
} */
.text-gray {
    color: #b9b9b9;
}

.form-error {
    color: #ff2727;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    background: #c292442b;
    padding: 1px 12px;
}

.export-touch-1-img {
    right: -100px !important;
}

.export-touch-2-img {
    left: -100px !important;
}

.welcome-head.heading-container,
.contact-head.heading-container {
    max-width: unset;
    text-align: left;
}

.contact-head.heading-container p::after {
    right: -115%;
    width: 112%;
}

.contact-form-section .contact-box {
    box-shadow: unset;
}

.form-control,
.form-select {
    border-radius: 0;
    padding: 11.4px 15px;
    border: unset;
}

.separate-line {
    border-bottom: 3px solid #b7ac50;
}

.btn-icon {
    display: flex;
    position: relative;
    border-radius: 100%;
    background: #b7ac50;
    color: #f6f7f9;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 9px;
}

.btn-icon svg {
    margin: 0;
}

.about-background {
    background-image: url("../images/about-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #353535;
}


.medical-background {
    background-image: url("../images/medical-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #353535;
}

.medical-row .col-xl-6 {
    padding-top: 84px;
}

.medical-background form {
    position: relative;
    background-color: #b7ac50;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    max-width: 450px;
    border-radius: 8px;
}

.welcome-title,
.contact-title,
.about-background {
    position: relative;
}

.footer-head,
.footer-line {
    position: relative;

}

.footer-line {
    overflow: hidden;
}

.welcome-title::after,
.footer-head::after {
    position: absolute;
    bottom: -25px;
    right: 0;
    content: "";
    background-color: #b7ac50;
    width: 50%;
    height: 3px;
}

.footer-head::after {
    left: 0;
    height: 1px;
    top: 130%;
}



.welcome-title::before,
.contact-title::before {
    position: absolute;
    top: 129%;
    right: 0;
    content: "";
    background-color: #b7ac50;
    width: 3px;
    height: 800%;
}

.location-text {
    font-size: smaller;
}

.z-index-1 {
    z-index: 1;
}

.cannedian-text {
    color: #770303 !important;
    font-size: larger;
}

.social-icon {
    color: #ffff;
    font-size: xx-large;
}

.heading-container.contact-head {
    max-width: 50%;
}

.social-icon:hover,
a:hover {
    color: #b7ac50;
}

.verifiction-bg {
    background-image: url("../images/Aesthetic-Leaf.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100vh !important;
}

.our-story-img-conta,
.our-story-img {
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1/1;
}

.our-story-img {
    max-width: 100%;
}

.our-story-img-conta {
    border: 3px solid #000;
    padding: 10px;
    max-width: 403px;
    margin: auto;
}

.fw-semibold {
    font-weight: 500 !important;
}

.verifiction-bg::before {
    content: "";
    position: absolute;
    inset: 0 0 0 0;
    background: #285c2dab;
    z-index: 0;
}

.get-birth-container {
    position: relative;
    background-color: #ffffffed;
    max-width: 800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 70px;
    text-align: center;
    line-height: 1.7rem;
    letter-spacing: 1.3px;
    overflow: hidden;
}

.get-birth-container::before {
    position: absolute;
    content: "";
    background: #b1a8471a;
    inset: 0 0 0 0;
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
}

.get-birth-container h2.h2 {
    font-size: 35px;
    margin-bottom: 20px;
}

.btn-primary {
    background: #b6ad53;
}

.btn-dark,
.btn-primary {
    border: 0;
    border-radius: 0;
    font-size: medium;
    padding: 12px 50px;
    font-weight: 300;
}

.btn-primary.active,
.btn-primary:hover,
.btn:focus-visible,
.btn-primary.show,
.btn-primary:first-child:active {
    background-color: #777f20;
    border-color: #777f20;
}

label {
    margin-bottom: 0.5rem;
}

input,
optgroup,
select,
textarea {
    padding: 10px 15px !important;
    border: 0;
    width: 100%;
    transition: all 0.3s;
}

input:focus,
optgroup:focus,
select:focus,
textarea:focus {
    border-radius: 0;
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(183, 172, 80, 0.25);
}

a {
    text-decoration: none;
    font-weight: 500;
    color: #f3e5f5;
}

.nav-link {
    font-weight: 700;
    margin: 0 12px;
    color: #000000;
}

.contact-bg {
    background-image: url("../images/cannabis-trees.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #353535;
}

.contact-hero-content {
    padding: 70px 0 50px;
}

.contact-bg-one-section,
.contact-bg-two-section {
    inset: 0 0 0 0;
}

.contact-bg-two-section h1 {
    font-size: 3.5rem;
    font-weight: 700;
}

.contact-box {
    background: #fff;
    padding: 30px;
    text-align: center;
    box-shadow: -4px 4px 25px -4px #cccccce0;
}

.note-heading svg {
    font-size: x-large;
    color: #275b2d;
}

.card-note {
    background: #95C382;
    position: relative;
    width: 100%;
    padding-bottom: 35px;
    box-shadow: -6px -5px 5px #0000000d
}

.card-note::after,
.card-note::before {
    position: absolute;
    width: 45px;
    height: 45px;
    content: "";
    bottom: 0;
}

.card-note::after {
    right: 0;
    border: 23px solid #89b677;
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.des-plant-product .result-label {
    line-height: 1rem;
}

.des-plant-product .result-label small {
    font-size: 12px;
}

.des-plant-product td,
.des-plant-product th {
    padding: 14px 0;
}

.des-plant-product th {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: #7da66d;
}

.card-note::before {
    right: 0;
    background: #c0b665;
}

.note-heading {
    background: #89b677;
    padding: 15px 15px;
}

.note-heading h4 {
    margin-bottom: 0;
}

.note-body {
    padding: 15px 15px;
}

.form-group {
    position: relative;
}

.contact-form-section {
    padding: 80px 00;
}

.upcoming {
    aspect-ratio: 1/1;
}

.step-treatment h2 {
    line-height: 1.2;
}

.step-treatment h2:nth-child(2) {
    margin-left: 15%;
}

.step-treatment h2:nth-child(3) {
    margin-left: 18%;
}

.heading-container.step-treatment p::after,
.heading-container.step-treatment p::before {
    background-color: #b7ac50;
}

.heading-container.step-treatment p::after {
    top: 50%;
    right: -55%;
    width: 50%;
}

.heading-container.step-treatment p::before {
    top: 50%;
    left: -10%;
    width: 8%;
}

.treatment-box {
    height: 600px;
    background: url('../images/roadSteps.png') no-repeat;
    background-position: bottom;
    background-size: contain;
    position: relative;
}

.treatment-box .step {
    position: absolute;
    background: #fff0;
    backdrop-filter: blur(5px);
    border-radius: 10px;
}

.treatment-box .step.step-1 {
    bottom: 18%;
    left: 5%;
}

.treatment-box .step.step-2 {
    bottom: 40%;
    right: 4%;
}

.treatment-box .step.step-3 {
    top: 14%;
    left: 18%;
}

.treatment-box .step.step-4 {
    top: 5.5%;
    right: 5%;
}

.treatment-box .step-info::after {
    position: absolute;
    content: "";
    width: 3px;
    height: 250px;
    top: 0;
    right: 0;
}

.treatment-box .step-info-1::after {
    background: #75963B;
}

.treatment-box .step-info-2::after {
    background: #b7ac50;
}

.treatment-box .step-info-3::after {
    background: #E7BE8C;
}

.treatment-box .step-info-4::after {
    background: #B3B3B3;
}


.treatment-box .step-info-2 .step-icon {
    left: -26px;
}

.treatment-box .step-info {
    position: relative;
    padding-right: 25px;
}

.treatment-box .step-info.step-info-2 {
    padding-right: unset;
    padding-left: 30px;
}

.treatment-box .step-info.step-info-2::after {
    left: 0;
}

.treatment-box .step-icon {
    position: absolute;
    background: #ffffff;
    border: 3px solid #b7ac50;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    right: -26px;
    top: -54px;
}

.treatment-box .step-icon.user {
    border-color: #75963B;
    color: #75963B;
}

.treatment-box .step-icon.book {
    border-color: #b7ac50;
    color: #b7ac50;
}

.treatment-box .step-icon.phone {
    border-color: #E7BE8C;
    color: #E7BE8C;
}

.treatment-box .step-icon.ambulance {
    border-color: #B3B3B3;
    color: #B3B3B3;
}

.step-head-1,
.step-head-2,
.step-head-3 {
    position: relative;
    text-align: start;
}

.step-head-1::after {
    position: absolute;
    content: "";
    left: 0px;
    width: 20%;
    height: 3px;
    border-radius: 5px;
    bottom: 0px;
    background-color: #b7ac50;
}

.step-head-2::after {
    position: absolute;
    content: "";
    left: 0px;
    width: 20%;
    height: 3px;
    border-radius: 5px;
    bottom: 0px;
    background-color: #b7ac50;
}

.step-head-3::after {
    position: absolute;
    content: "";
    left: 0px;
    width: 68%;
    height: 3px;
    border-radius: 5px;
    bottom: 0px;
    background-color: #b7ac50;
}


/* Product Card */
.product-flip-card {
    background-color: transparent;
    width: 278px;
    height: 400px;
    perspective: 1000px;
    margin: auto;
}

.product-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: all 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 6px;
    border: 2px solid #000;
}

.product-flip-card:hover .product-flip-card-inner {
    transform: rotateY(180deg);
    padding: 0;
}

.product-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    transform: rotateY(180deg);
    top: 0;
    top: 0;
    left: 0;
    padding: 15px;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-flip-card-front {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: rgb(255, 255, 255);
    display: flex;
    align-items: end;
    transform-style: preserve-3d;
}

.product-img {
    position: absolute;
    width: 100%;
    height: 100%;
}

.product-name {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 15px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 150px 15px 15px 15px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
}

.product-name * {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.product-name h3 {
    margin-bottom: 0;
}

.product-name .hover-btn {
    width: 55px;
    height: 45px;
    margin-bottom: 0;
}


.product-flip-card-back h4 {}

.product-flip-card-back p {
    font-size: 13px;
    font-weight: 300;
}

.Accepting-Patients .row ul {
    margin: 0;
    list-style: none;
    padding: 0;
}

.Accepting-Patients .row ul li {
    padding: 5px 5px 5px 38px;

    /* background: url('../images/ul-style.png') no-repeat; */
    /* background-size: 14px 14px;
  background-position: 12px 12px; */

    /* background: url('../images/point.png') no-repeat;
  background-size: 20px 20px;
  background-position: 9px 10px; */

    font-size: 1rem;
    background-color: #ffffff82;
    margin-bottom: 10px;
    border-radius: 5px;
}

.swal2-title {
    font-size: 1.5em !important;
}


@media screen and (max-width: 992px) {
    .box-desktop {
        display: none;
    }
}

body>img {
    display: none;
}

header.dashbaord .profile-pic {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #cccccc;
    object-fit: cover;
}

header.dashbaord {
    box-shadow: unset;
    border-bottom: 2px solid #ededed;
    height: 70px;
}

.nav-links-cont {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
}

header.dashbaord .nav-link {
    font-weight: 700;
    margin: 0 20px;
    color: #c7c7c7;
}

header.dashbaord .nav-link.active {
    color: #c0b665;
    position: relative;
}

header.dashbaord .nav-link.active::after {
    content: "";
    width: 4px;
    border-bottom: 2px solid #c0b665;
    position: absolute;
    left: 0;
    bottom: -22px;
    width: 100%;
}

/* ------------ dashbaord --------------- */

.dashbaord .box-conta {
    margin-top: 70px;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: row;
}

.dashbaord .box-conta .sidebar {
    width: 280px;
    height: 100%;
    background-color: #c0b665;
    margin-left: -280px;
    transition: all 0.5s;
    position: relative;
    display: flex;
    flex-direction: column;
}

.dashbaord .box-conta .sidebar.show {
    margin-left: 0;
}

.dashbaord .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.icon-btn {
    cursor: pointer;
}

.dashbaord {
    background-color: #F0EFE9;
}

.dashbaord header {
    box-shadow: unset;
    height: 70px;
}

.content-header {
    min-height: 60px;
    padding: 15px 35px;
    padding-left: 75px;
}

.content-body {
    background-color: #ffffff;
    flex-grow: 1;
}

.dashbaord header>.container-fluid {
    padding: 0 15px;
}

.sidebar.show+.main-content .content-header,
.content-body {
    padding: 15px 35px;
    padding-left: 35px;
}

.sidebar .icon-btn {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: -58px;
    transition: all 0.5s;
}

.sidebar.show .icon-btn {
    right: -18px;
}

.sidebar .icon-btn:hover {
    box-shadow: 2px 2px 10px #0000002d;
}

.profile-info {
    text-align: center;
    padding: 25px 10px;
}

.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 15px auto;
}

.profile-pic img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.profioe-details h4 {
    margin-bottom: 0;
}

.profioe-details span {
    font-size: 13px;
}

.navbar-container {
    padding: 0 15px 15px;
    flex-grow: 1;
    overflow: hidden;
}

ul.links-con {
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #ADA45B;
    border-radius: 15px;
    padding: 10px;
    height: 100%;
    overflow-y: auto;
}

.links-con .nav-link {
    padding: 5px 15px;
    margin: 0;
    border-radius: 8px;
    margin: 4px 0;
    transition: all 0.5s;
    border-left: 3px solid transparent;
    font-weight: 400;
}

.links-con .active.nav-link,
.links-con .nav-link:hover {
    background-color: #c0b665;
    color: #ffffff;
    border-color: #ffffff;
}

.medical-step-form input,
.medical-step-form textarea {
    border: 2px solid #b7ac50;
}

.MuiRadio-root.Mui-checked {
    color: #b7ac50 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #b7ac50 !important;
}


.medical-step-form .MuiGrid-root .MuiFormControl-root {
    width: 100%;
    border: 2px solid #b7ac50;
}


.medical-step-form .MuiGrid-root .MuiFormControl-root input {
    border: none;
}

.medical-step-form .MuiFormControl-root fieldset {
    border: none;
}